@media (prefers-reduced-motion: no-preference) {
	.spinning {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.no-change-link, .no-change-link:hover, .no-change-link:visited, .no-change-link:active {
	color: inherit;
	text-decoration: none;
}

#root {
	color: #E2DEB9;
}

.page {
	height: 100%;
}

.centerAllChildren {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	text-align: center;
}